import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Plus } from "lucide-react";

const MenuProductList = ({ filteredProducts, categories }) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const categoryRefs = useRef({}); // Create a ref to store category refs

  const categorizedProducts = categories.map((category) => ({
    category,
    products: filteredProducts.filter(
      (product) => product.category === category
    ),
  }));

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToCategory = (category) => {
    const categoryRef = categoryRefs.current[category];
    if (categoryRef) {
      categoryRef.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="container-product">
      {categorizedProducts.length > 0 ? (
        categorizedProducts.map(({ category, products }) => (
          <div
            key={category}
            ref={(el) => (categoryRefs.current[category] = el)}
          >
            <h2
              className="category"
              onClick={() => scrollToCategory(category)}
              style={{ cursor: "pointer" }}
            >
              {category}
            </h2>
            <div>
              {products.map((product) => (
                <div key={product.id}>
                  <Link
                    to={`/meal/${product.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className={
                        product.is_visible ? `product` : `product-notVisible`
                      }
                    >
                      <div className="container-content">
                        <img src={product.image} alt={product.name} />
                        <div className="content">
                          <div className="text">
                            {" "}
                            <h3>{product.name}</h3>
                            <span
                              className={
                                product.is_visible
                                  ? `is-not-visible`
                                  : `is-visible`
                              }
                            >
                              {" "}
                              (غير متاح)
                            </span>
                          </div>
                          <p className="price">
                            <span>{product.price}</span> ر.س
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          product.is_visible ? `product-btn` : `is-not-visible`
                        }
                      >
                        <button>
                          <Plus size={15} />
                        </button>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <div className="no-data">لا توجد منتجات في القائمة</div>
      )}
    </div>
  );
};

export default MenuProductList;
