import { Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

import greenlocat from "../../assets/images/greenLocat.png";

const Makeorder = () => {
  return (
    <>
      <h2>شكرا لطلبك , سوف يتم تحضير الطلب</h2>
      <Button
        style={{
          backgroundColor: "#37BD6B",
          textAlign: "center",
          margin: "0",
          border: "none",
          padding: "10px 7px",
        }}
      >
        <Link to="/myOrders">تتبع الطلب</Link>
      </Button>
    </>
  );
};

export default Makeorder;
