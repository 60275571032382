import React from "react";
import { Scrollbar, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

const Slider = ({ images }) => {
  return (
    <Swiper
      // install Swiper modules
      modules={[Scrollbar, A11y, Autoplay]}
      slidesPerView={1}
      autoplay={{ delay: 3000 }}
      loop={true}
      style={{ width: "100%", height: "240px", overflow: " hidden" }}
    >
      {images?.map((image, index) => (
        <SwiperSlide
          key={index}
          style={{
            display: "flex",
            justifyContent: " center",
            alignItems: " center",
          }}
        >
          <img
            src={image.image}
            alt="image-slide"
            style={{
              borderRadius: "0 0 30px 30px ",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
