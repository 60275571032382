import "../showCart/sowCart.css";
import { Link } from "react-router-dom";

const ShowCart = () => {
  return (
    <div className="main-content-cart">
      <div className="showCart">
        <Link to="/cart" className="link">
          <div className="main-content">
            <div className="content">
              <p>
                <span>5</span> مشاهدة السلة
              </p>
            </div>
            <p className="price">199 ر.س</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ShowCart;
