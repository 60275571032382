import axios from "axios";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";

import { Link, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { Eye, EyeOff, LockKeyhole, Mail } from "lucide-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../../axiosConfig/axios";

import logo from "../../assets/images/logo.svg";
import Sms from "../../assets/images/form-icons/sms.svg";
import Lock from "../../assets/images/form-icons/lock.svg";
import Blur from "../../assets/images/form-icons/blur.png";

export default function Login() {
  const [showPassword, setShowPassword] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const emailOrPhoneRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$|^5\d{8}$/;

  let validationSchema = Yup.object({
    login: Yup.string()
      .matches(emailOrPhoneRegExp, "يجب إدخال بريد إلكتروني أو رقم هاتف صالح")
      .required("يجب إدخال البريد الإلكتروني أو رقم الهاتف"),
    password: Yup.string()
      .min(8, "يجب أن تكون كلمة المرور 8 أحرف على الأقل")
      .max(20, "لا يجب أن تزيد كلمة المرور عن 20 أحرف")
      .required("يجب إدخال كلمة المرور"),
  });

  async function submitForm(values) {
    setLoading(true);
    const { data } = await axiosInstance.post("login", values);

    if (data?.status === 200) {
      setLoading(false);
      toast.success(data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
      localStorage.setItem("token", data?.data.token);
      navigate("/menu");
    } else if (data?.status === 101) {
      setLoading(false);
      toast.error(data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    } else {
      setLoading(false);
      toast.success(data?.data.otp, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        closeOnClick: false,
      });
      localStorage.setItem("token", data?.data.token);
      localStorage.setItem("mobile", data?.data.mobile);
      setTimeout(() => {
        navigate("/otp");
      }, 10000);
    }
  }

  let formik = useFormik({
    initialValues: {
      login: "",
      password: "",
      role: "user",
    },
    validationSchema,
    onSubmit: submitForm,
  });

  return (
    <div className="auth">
      <img src={Blur} alt="blur-top-img" className="blur-top-img" />
      <div className="form-container">
        <div className="content">
          <img src={logo} />
          <h3>مرحبا , بعودتك</h3>
          <p style={{ marginTop: "20px" }}>
            قم بتسجيل الدخول حتي يمكنك تصفح التطبيق
          </p>
        </div>
        <form onSubmit={formik.handleSubmit}>
          {/* login */}
          <div className="input-container">
            <div className="input-wrapper">
              <div className="input-icon">
                <img src={Sms} alt="Sms" />
              </div>
              <input
                placeholder="البريد الالكترونى او الجوال"
                onBlur={formik.handleBlur}
                id="login"
                name="login"
                onChange={formik.handleChange}
                value={formik.values.login}
              />
            </div>
            {formik.errors.login && formik.touched.login ? (
              <p className="error-danger">{formik.errors.login}</p>
            ) : (
              ""
            )}
          </div>

          {/* password */}
          <div className="input-container">
            <div className="input-wrapper">
              <div className="input-icon">
                <img src={Lock} alt="Lock" />
              </div>
              <input
                placeholder="كلمة المرور"
                type={showPassword ? "password" : "text"}
                onBlur={formik.handleBlur}
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {showPassword ? (
                <Eye
                  className="input-icon-eye"
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <EyeOff
                  className="input-icon-eye"
                  onClick={() => setShowPassword(true)}
                />
              )}
            </div>
            {formik.errors.password && formik.touched.password ? (
              <p className="error-danger">{formik.errors.password}</p>
            ) : (
              ""
            )}
          </div>

          {isLoading ? (
            <button className="btn-form">
              <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                width="30"
                visible={true}
              />
            </button>
          ) : (
            <button
              type="submit"
              disabled={!(formik.isValid && formik.dirty)}
              className="btn-form"
            >
              تسجيل الدخول
            </button>
          )}

          <div className="to-login">
            <p> ليس لديك حساب؟ </p>
            <Link to="/register"> تسجيل جديد</Link>
          </div>
        </form>
      </div>

      <ToastContainer />
    </div>
  );
}
