import { useEffect, useState } from "react";
import "./cart.css";
import { Button } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import { Trash2 } from "lucide-react";
import ProductCartItem from "./ProductCartItem";
import emptyCart from "../../assets/images/empty-cart.svg";
import { axiosTokenInstance } from "../../axiosConfig/axios";

import cartLocation from "../../assets/images/cart-location.svg";
import arrow from "../../assets/images/arrow.svg";
import Note from "../../components/note/note";
const Card = () => {
  const navigate = useNavigate();

  const [cartProductDetails, setCartProductDetails] = useState([]);
  const [restaurantDetails, setRestaurantDetails] = useState({});
  const [total_price_with_taxs, setTotal_price_with_taxs] = useState(null);
  let [quantity, setQuantity] = useState({});

  // get cart product details
  const getCartDetails = () => {
    axiosTokenInstance.get("user/cart").then((res) => {
      // console.log(res.data.data);

      if (res.data.data) {
        setCartProductDetails(res.data.data.products);
        setRestaurantDetails(res.data.data.restaurant);
        setTotal_price_with_taxs(res.data.data.total_price_with_taxs);

        const quantities = {};
        res.data.data.products.forEach((product) => {
          quantities[product.productCartId] = product.quantity;
        });
        setQuantity(quantities);
      } else {
        setCartProductDetails([]);
      }
    });
  };

  useEffect(() => {
    getCartDetails();
  }, []);

  //delete cart product details
  const emptyProductsCart = () => {
    axiosTokenInstance.post("user/cart/emptyCart", {}).then((res) => {
      if (res.status === 200) {
        toast.success(" Deleted successfully .", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });

        getCartDetails();
      }
    });
  };

  //delete product from cart
  const deleteProduct = (id) => {
    axiosTokenInstance.post(`user/cart/removeItem/${id} `, {}).then((res) => {
      if (res.status === 200) {
        toast.success(" Deleted successfully .", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });

        getCartDetails();
      }
    });
  };

  // Update quantity for specific product
  const handleQuantityChange = (productCartId, newQuantity) => {
    setQuantity((prevQuantity) => ({
      ...prevQuantity,
      [productCartId]: newQuantity,
    }));
  };

  // prepare data for updating quantity
  const requestUpdateQuantity = Object.entries(quantity).map(
    ([productCartId, qty]) => ({
      productCartId,
      quantity: qty,
    })
  );

  const updateQuantity = () => {
    axiosTokenInstance
      .post(`user/cart/updateQuantityMulti`, {
        items: requestUpdateQuantity,
      })
      .then((res) => {
        console.log(res);
      });
  };

  return (
    <div style={{ padding: "0 20px" }}>
      <div>
        <h2
          style={{
            fontSize: "18px",
            fontWeight: "500",
            color: "black",
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          سلة المشتريات
        </h2>
      </div>

      {cartProductDetails?.length > 0 ? (
        <>
          {/* Start header about restaurant*/}
          <div className="ordered-from-restaurant">
            <h3>تم الطلب من</h3>
            <hr />
            <div className="content">
              <div className="details-container">
                <img src={cartLocation} alt="cart-location" />
                <div className="details">
                  <h4>{restaurantDetails.name}</h4>
                  <p>{restaurantDetails.address}</p>
                </div>
              </div>
              <Link to="/menu">
                <img src={arrow} alt="arrow" />
              </Link>
            </div>
          </div>
          {/* End header about restaurant  */}

          {/* Start cart products details */}
          <div className="product-container">
            <div className="header">
              <p>تفاصيل الطلب</p>

              <div onClick={emptyProductsCart}>
                <Trash2 style={{ color: "red" }} />
              </div>
            </div>

            <hr />

            {cartProductDetails?.map((product, index) => {
              return (
                <ProductCartItem
                  key={product.productCartId}
                  productDetails={product}
                  quantity={quantity[product.productCartId] || 1}
                  setQuantity={(newQty) =>
                    handleQuantityChange(product.productCartId, newQty)
                  }
                  deleteProductFun={() => deleteProduct(product.productCartId)}
                  showDivider={index < cartProductDetails.length - 1}
                />
              );
            })}
            {/* <Button onClick={updateQuantity}>Update Quantity</Button> */}
          </div>
          {/* End cart products details */}

          {/* Start  */}
          <div>
            <div>
              <p>الاجمالى</p>
              <p></p>
            </div>
          </div>

          {/* Note of products details */}
          <Note />
          {/* End Note of products details */}

          {/* <div>
              <Button
                style={{
                  backgroundColor: "#37BD6B",
                  textAlign: "center",
                  margin: "0",
                  border: "none",
                  padding: "10px 7px",
                }}
              >
                تأكيد الطلب
              </Button>
              <Button
                style={{
                  backgroundColor: "white",
                  textAlign: "center",
                  margin: "0",
                  border: "none",
                  padding: "10px 7px",
                }}
              >
                اضافة المزيد
              </Button>
            </div> */}
        </>
      ) : (
        <div className="empty-cart">
          <div className="content">
            <img src={emptyCart} alt="empty-cart" />
            <h3>"سلتك خاوية! متى بتطلب؟"</h3>
            <p>فى انتظار طلبك</p>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default Card;
