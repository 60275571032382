import "./cart.css";
import { Minus, Plus, Trash2 } from "lucide-react";
import { Button } from "react-bootstrap";

function ProductCartItem({
  productDetails,
  quantity,
  setQuantity,
  deleteProductFun,
  showDivider,
}) {
  const incrementCount = () => setQuantity(quantity + 1);
  const decrementCount = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

  return (
    <>
      <div>
        <div className="productDetails">
          <img
            src={productDetails.product.image}
            alt={productDetails.product.name}
          />
          <div className="product-content">
            <div className="header">
              <h3>{productDetails.product.name}</h3>

              <div>
                <Trash2
                  onClick={() => {
                    deleteProductFun();
                  }}
                  style={{ color: "red" }}
                />
              </div>
            </div>
            <div className="size-details">
              {productDetails.sizes.name}
              {productDetails.options.length > 0
                ? productDetails.options.map((option) => {
                    return (
                      <span key={option.option_id}>
                        {" , "}
                        {option.name}
                      </span>
                    );
                  })
                : " "}
            </div>
            <div className="price">
              {productDetails.sizes.price_with_tax} رس
            </div>

            <div className="btns-container">
              <Button className="btn" onClick={incrementCount}>
                <Plus size={"15"} />
              </Button>
              <div className="quantity">{quantity}</div>
              <Button
                className="btn"
                onClick={decrementCount}
                disabled={quantity === 1}
              >
                <Minus size={"15"} />
              </Button>
            </div>
          </div>
        </div>
        {showDivider && <hr />}
      </div>
    </>
  );
}

export default ProductCartItem;
