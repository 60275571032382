import React, { useEffect, useState } from "react";
import locat from "../../assets/images/location.svg";
import Star from "../../assets/images/star.svg";
import Clock from "../../assets/images/Vector.svg";

const RestaurantHeader = ({ resturantInfo }) => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const {
    name,
    address,
    distance,
    rating,
    logo,
    openTime,
    closeTime,
    m_categories,
  } = resturantInfo;

  const menu_categories = m_categories.split(",");

  return (
    <div className="contain">
      <div className={isScrolling ? `noScroll` : `scroll`}>
        <div className="image-container">
          <img src={logo} alt="logo" className="logo" />
        </div>
        <h3>{name}</h3>
      </div>

      <div className={isScrolling ? `main-header` : `noMain-header`}>
        <img src={logo} alt="logo" className="logo2" />

        <h3>{name}</h3>
      </div>

      <div className={isScrolling ? `none-main-details` : `main-details`}>
        <div className="location">
          <p>{address}</p>
        </div>

        <div className="details">
          <div className="address">
            <img src={locat} alt="location" />
            <p>{distance}</p>
          </div>
          <div className="divider"></div>
          <div className="rating">
            <img src={Star} alt="star" />
            <p>{rating}</p>
          </div>
          <div className="divider"></div>
          <div className="clock">
            <img src={Clock} alt="clock" />
            <p>
              من {openTime ? openTime.slice(10) : " "} إلى{" "}
              {closeTime ? closeTime.slice(10) : " "}
            </p>
          </div>
        </div>

        <div className="menu-categories">
          {menu_categories.length > 0
            ? menu_categories.map((category) => (
                <div key={category} className="category">
                  {category}
                </div> // Make sure each item has a unique key
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default RestaurantHeader;
