import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Downloading from "../pages/downloading/downloading";
import Meal from "../pages/meal/meal";
import Card from "../pages/cart/cart";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import Otp from "../pages/Otp/Otp";
import RestaurantMenu from "../pages/RestaurantMenu/RestaurantMenu";
import Order from "../pages/order/Order";
import Makeorder from "../pages/order/Makeorder";
import Myorders from "../pages/order/Myorders";
import Myorderdetails from "../pages/order/Myorderdetails";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,

    children: [
      {
        path: "webfeastap",
        element: <Downloading />,
      },
      {
        path: "menu",
        element: <RestaurantMenu />,
      },
      {
        path: "meal/:id",
        element: <Meal />,
      },
      {
        path: "cart",
        element: <Card />,
      },
      {
        path: "order",
        element: <Order />,
      },
      {
        path: "makeOrder",
        element: <Makeorder />,
      },
      {
        path: "myOrders",
        element: <Myorders />,
      },
      {
        path: "myOrderDetails/:id",
        element: <Myorderdetails />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "otp",
        element: <Otp />,
      },
    ],
  },
]);
