import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Myorders() {
  const [myOrders, setMyOrders] = useState([]);

  const token = localStorage.getItem("token");

  const getAllMyOrders = async () => {
    axios
      .get("https://development.feastap.com/api/user/order", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setMyOrders(res.data.data);
        }
      });
  };

  useEffect(() => {
    getAllMyOrders();
  }, []);

  return (
    <div>
      <h2>طلباتي</h2>
      <div>
        {myOrders?.map((order) => {
          return (
            <div key={order.orderId}>
              <p>{order.restaurantAdress}</p>
              <p>{order.created.slice(0, 16).replace("T", " ")}</p>
              <p>{order.branch}</p>
              <Button
                style={{
                  backgroundColor: "#37BD6B",
                  textAlign: "center",
                  margin: "0",
                  border: "none",
                  padding: "10px 7px",
                }}
              >
                <Link to={`/myOrderDetails/${order.orderId}`}>
                  تفاصيل الطلب
                </Link>
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Myorders;
