import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://development.feastap.com/api/",
  headers: {
    Accept: "application/json",
    // Accept: "*/*",
    "Content-Type": "application/json",
    "Accept-Language": "ar",

    // "Access-Control-Allow-Origin": "*",
    // mode: "cors",
  },
});

const axiosTokenInstance = axios.create({
  baseURL: "https://development.feastap.com/api/",
  headers: {
    Accept: "application/json",
    // Accept: "*/*",
    "Content-Type": "application/json",
    "Accept-Language": "ar",
    // "Access-Control-Allow-Origin": "*",
    // mode: "cors",
  },
});

//request
axiosTokenInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//response
axiosTokenInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
export { axiosTokenInstance };
