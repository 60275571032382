import React from "react";
import { Link } from "react-router-dom";
import { Plus } from "lucide-react";
import discount from "../../assets/images/discount 1.svg";

const OfferList = ({ offers }) => {
  return (
    <div className="container-product">
      {offers && offers.length > 0 ? (
        offers?.map((offer) => (
          <div key={offer.id}>
            <Link to={`/meal/${offer.id}`} style={{ textDecoration: "none" }}>
              <div className="product">
                <div className="container-content">
                  <img src={offer.image} alt={offer.name} />
                  <div className="content">
                    <h3>{offer.name}</h3>
                    <p className="price">
                      <span>{offer.price}</span> ر.س
                    </p>
                  </div>
                </div>
                <div className="product-btn">
                  <button>
                    <Plus size={15} />
                  </button>
                </div>
              </div>
            </Link>
          </div>
        ))
      ) : (
        <div className="no-data">
          <img src={discount} alt="discount" />
          <h6>لايوجد عروض</h6>
        </div>
      )}
    </div>
  );
};

export default OfferList;
