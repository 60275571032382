import React, { useState } from "react";
import "./menu.css";

const TabBar = ({ tabs, mainClass, nameOfClass, onTabClick }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleClick = (tab) => {
    setActiveTab(tab);
    if (onTabClick) onTabClick(tab);
  };

  return (
    <div className={mainClass}>
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={`${nameOfClass} ${activeTab === tab ? "active" : ""}`}
          onClick={() => handleClick(tab)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default TabBar;
