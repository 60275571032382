import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { Mail, Phone, LockKeyhole, Eye, EyeOff } from "lucide-react";

import icon from "../../assets/icon.jpeg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../../axiosConfig/axios";
import logo from "../../assets/images/logo.svg";
import User from "../../assets/images/form-icons/profile-circle.svg";
import Sms from "../../assets/images/form-icons/sms.svg";
import Call from "../../assets/images/form-icons/call.svg";
import Lock from "../../assets/images/form-icons/lock.svg";
import Blur from "../../assets/images/form-icons/blur.png";

export default function Register() {
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  let validationSchema = Yup.object({
    name: Yup.string()
      .min(3, "الاسم لا يقل عن 3 أحرف ")
      .max(10, "الاسم لا يزيد عن 10 أحرف")
      .required("يجب ادخال الاسم"),
    email: Yup.string()
      .email("البريد الإلكتروني غير صحيح")
      .required("يجب ادخال البريد الإلكتروني"),
    password: Yup.string()
      .min(8, "يجب أن تكون كلمة المرور 8 أحرف على الأقل")
      .max(20, "لا يجب أن تزيد كلمة المرور عن 20 أحرف")
      .required("يجب ادخال كلمة المرور"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password")], "تأكيد كلمة المرور غير متطابق")
      .required("يجب تأكيد كلمة المرور"),
    mobile: Yup.string()
      .matches(/^5[0-9]{8}$/, "رقم هاتف غير صحيح")
      .required("يجب ادخال رقم الهاتف"),
  });

  async function submitForm(values) {
    setLoading(true);
    const { data } = await axiosInstance.post("register", values);

    if (data?.status === 102) {
      setLoading(false);
      localStorage.setItem("token", data?.data.token);
      localStorage.setItem("mobile", data?.data.mobile);
      toast.success(data?.data.otp, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        closeOnClick: false,
      });

      setTimeout(() => {
        navigate("/otp");
      }, 10000);
    } else {
      toast.error(data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
      });
      setLoading(false);
    }
  }

  let formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      mobile: "",
      role: "user",
    },
    validationSchema,
    onSubmit: submitForm,
  });

  return (
    <div className="auth">
      <img src={Blur} alt="blur-top-img" className="blur-top-img" />
      <div className="form-container">
        <div className="content">
          <img src={logo} />
          <h3>تسجيل جديد</h3>
        </div>
        <form onSubmit={formik.handleSubmit}>
          {/* User Name */}
          <div className="input-container">
            <div className="input-wrapper">
              <div className="input-icon">
                <img src={User} alt="user" />
              </div>
              <input
                type="text"
                placeholder="الاسم"
                onBlur={formik.handleBlur}
                id="name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </div>
            {formik.errors.name && formik.touched.name ? (
              <p className="error-danger">{formik.errors.name}</p>
            ) : (
              ""
            )}
          </div>

          {/* Email */}
          <div className="input-container">
            <div className="input-wrapper">
              <div className="input-icon">
                <img src={Sms} alt="Sms" />
              </div>
              <input
                type="email"
                placeholder="البريد الإلكتروني "
                onBlur={formik.handleBlur}
                id="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </div>
            {formik.errors.email && formik.touched.email ? (
              <p className="error-danger">{formik.errors.email}</p>
            ) : (
              ""
            )}
          </div>

          {/* mobile */}
          <div className="input-container">
            <div className="input-wrapper">
              <div className="input-icon">
                <img src={Call} alt="Call" />
              </div>
              <input
                type="tel"
                placeholder="رقم الجوال"
                maxLength={9}
                onBlur={formik.handleBlur}
                id="mobile"
                name="mobile"
                value={formik.values.mobile}
                onChange={formik.handleChange}
              />
              <div className="input-img">
                <span>966+</span>
                <img src={icon} />
              </div>
            </div>
            {formik.errors.mobile && formik.touched.mobile ? (
              <p className="error-danger">{formik.errors.mobile}</p>
            ) : (
              ""
            )}
          </div>

          {/* password */}
          <div className="input-container">
            <div className="input-wrapper">
              <div className="input-icon">
                <img src={Lock} alt="Lock" />
              </div>
              <input
                placeholder="كلمة المرور"
                type={showPassword ? "password" : "text"}
                onBlur={formik.handleBlur}
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {showPassword ? (
                <Eye
                  className="input-icon-eye"
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <EyeOff
                  className="input-icon-eye"
                  onClick={() => setShowPassword(true)}
                />
              )}
            </div>
            {formik.errors.password && formik.touched.password ? (
              <p className="error-danger">{formik.errors.password}</p>
            ) : (
              ""
            )}
          </div>

          {/* Confirm Password */}
          <div className="input-container">
            <div className="input-wrapper">
              <div className="input-icon">
                <img src={Lock} alt="Lock" />
              </div>
              <input
                placeholder="تأكيد كلمة المرور"
                type={showConfirmPassword ? "password" : "text"}
                onBlur={formik.handleBlur}
                id="password_confirmation"
                name="password_confirmation"
                value={formik.values.password_confirmation}
                onChange={formik.handleChange}
              />
              {showConfirmPassword ? (
                <Eye
                  className="input-icon-eye"
                  onClick={() => setShowConfirmPassword(false)}
                />
              ) : (
                <EyeOff
                  className="input-icon-eye"
                  onClick={() => setShowConfirmPassword(true)}
                />
              )}
            </div>
            {formik.errors.password_confirmation &&
            formik.touched.password_confirmation ? (
              <p className="error-danger">
                {formik.errors.password_confirmation}
              </p>
            ) : (
              ""
            )}
          </div>

          {isLoading ? (
            <button className="btn-form">
              <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                width="30"
                visible={true}
              />
            </button>
          ) : (
            <button
              type="submit"
              disabled={!(formik.isValid && formik.dirty)}
              className="btn-form"
            >
              إنشاء حساب
            </button>
          )}

          <div className="to-login">
            <p> لديك حساب </p>
            <Link to="/login">سجل دخول</Link>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
