import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function Myorderdetails() {
  const params = useParams();
  const orderId = params.id;
  const token = localStorage.getItem("token");

  const [myOrderDetails, setMyOrderDetails] = useState({});

  const getOrderDetails = async () => {
    axios
      .get(`https://development.feastap.com/api/user/order/${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data);

        if (res.data.status === 200) {
          setMyOrderDetails(res.data.data);
        }
      });
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  return (
    <div>
      <h2>تفاصيل الطلب</h2>
      <div>
        <div>
          <h3>{myOrderDetails.info.resturantName}</h3>
          <div>
            <p></p>
            <p></p>
          </div>
          <hr />
          <div>
            <p>المجموع</p>
            <p></p>
          </div>
        </div>

        <div>
          <h3></h3>
          <div>
            <p></p>
            <p></p>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Myorderdetails;
