import { Form } from "react-bootstrap";
import Dish from "../../assets/images/dish 3.svg";
import Calory from "../../assets/images/huobi-token-(ht).svg";
import Category from "../../assets/images/category-2.svg";
import { Fragment } from "react";

import dollar from "../../assets/images/product-details/dollar-square.svg";
import message from "../../assets/images/product-details/message-add.svg";

import Note from "../../../src/components/note/note";
const ProductDetails = ({
  Product,
  Sizes,
  selectedSize,
  setSelectedSize,
  productPrice,
  setProductPrice,
  productOptions,
  handleCheckboxChange,
  notes,
  setNotes,
}) => {
  return (
    <div className="meal-product">
      <div className="meal-img">
        <img src={Product.image} alt={Product.name} />
      </div>

      {/* start header of product details */}
      <div className="meal-content">
        <div className="dish">
          <img src={Dish} alt={Product.name} />
          <h3>{Product.name}</h3>
        </div>

        <div className="meal-container">
          <div className="meal">
            <img src={Calory} alt={Product.name} />
            <div>
              السعرات:{" "}
              {Sizes?.map((size, i) => (
                <Fragment key={i}>
                  {size.id === selectedSize ? `${size.calories}  كالوري` : ""}
                </Fragment>
              ))}
            </div>
          </div>

          <div className="meal">
            <img src={Category} alt={Product.name} />
            <div>القسم: {Product.category}</div>
          </div>
        </div>

        <hr />
        <div className="price">
          <p>
            {productPrice} رس{" "}
            <span>
              (
              {Sizes?.map((size, i) => (
                <Fragment key={i}>
                  {size.id === selectedSize ? `${size.name_ar} ` : ""}
                </Fragment>
              ))}
              )
            </span>
          </p>
        </div>
      </div>
      {/* end header of product details */}

      <div className="main-container-details">
        {/* Size of products details */}
        <div className="size">
          <div className="icon-container">
            <img src={dollar} alt="dollar" />
            <h3>الأحجام و الأسعار</h3>
          </div>

          <div className="meal-form">
            <Form style={{ padding: "0" }}>
              <div key={"reverse-radio"}>
                {Sizes?.map((Size, index) => (
                  <>
                    {" "}
                    <div key={index} className="priceOfSize">
                      <Form.Check
                        reverse
                        label={Size.name_ar}
                        name="group1"
                        type="radio"
                        id={`reverse-radio-${index}`}
                        checked={selectedSize === Size.id} // Conditionally check based on state
                        onChange={() => {
                          setSelectedSize(Size.id);
                          setProductPrice(Size.price_with_tax); // Update price when size changes
                        }}
                      />
                      <p style={{ color: "#838383" }}>
                        {Size.price_with_tax} ر.س
                      </p>
                    </div>
                    <hr />
                  </>
                ))}
              </div>
            </Form>
          </div>
        </div>

        {/* Options of products details */}
        {productOptions?.length > 0 && (
          <>
            <div className="addon">
              <div className="icon-container">
                <img src={message} alt="message" />
                <h3>الاضافات</h3>
              </div>

              <div className="meal-form">
                <Form style={{ padding: "0" }}>
                  <>
                    <div key={"reverse-checkbox"} className="mb-3">
                      {productOptions?.map((productOption, index) => (
                        <div key={index} className="priceOfOptions">
                          <Form.Check
                            style={{ color: "#838383" }}
                            reverse
                            label={productOption.name}
                            name="group1"
                            type="checkbox"
                            id={`checkbox-${index}`}
                            onChange={(e) =>
                              handleCheckboxChange(e, productOption.id)
                            }
                          />

                          <p style={{ color: "#838383" }}>
                            {productOption.price_with_tax} ر.س
                          </p>
                        </div>
                      ))}
                    </div>
                    <hr />
                  </>
                </Form>
              </div>
            </div>
          </>
        )}

        {/* Note of products details */}
        <Note notes={notes} setNotes={setNotes} />
        {/* End Note of products details */}
      </div>
    </div>
  );
};

export default ProductDetails;
