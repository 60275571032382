import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosTokenInstance } from "../../axiosConfig/axios";
import OTP from "../../assets/images/form-icons/Otp.svg";
import Blur from "../../assets/images/form-icons/blur.png";

export default function Otp() {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const focusOtp1 = () => {
      const otp1Input = document.getElementById("otp6");
      if (otp1Input) {
        otp1Input.focus();
      }
    };

    // Focus on otp1 when the page loads
    focusOtp1();
  }, []);

  async function resendCode() {
    const mobile = localStorage.getItem("mobile");

    const { data } = await axiosTokenInstance.post(`resend/ResetCode`, {
      login: mobile,
    });

    if (data?.status === 200) {
      toast.success(data?.data.otp, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
      });
    } else {
      toast.error(data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
  }

  async function submitForm(values) {
    const { otp1, otp2, otp3, otp4, otp5, otp6 } = values;
    const otpCode = `${otp6}${otp5}${otp4}${otp3}${otp2}${otp1}`;

    setLoading(true);
    const { data } = await axiosTokenInstance.post(`verifiy`, {
      otp: otpCode,
    });

    console.log(data);

    if (data?.status === 200) {
      setLoading(false);
      navigate("/menu");
    } else {
      toast.error(data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
      setLoading(false);
    }
  }

  let formik = useFormik({
    initialValues: {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    },
    onSubmit: submitForm,
  });

  const handleChange = (e, field, nextField) => {
    const { value } = e.target;
    formik.setFieldValue(field, value);

    if (value.length === 1 && nextField) {
      const nextInput = document.getElementById(nextField);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  return (
    <div className="auth">
      <img src={Blur} alt="blur-top-img" className="blur-top-img" />
      <div className="otp-container">
        <div className="otp-content">
          <img src={OTP} alt="otp" className="otp" />
          <h2>كود تأكيد الحساب</h2>
          <h3>"تم إرسال الكود إلى إيميلك، فضلاً اكتبه لتأكيد الايميل."</h3>
        </div>
        <form onSubmit={formik.handleSubmit}>
          {/* OTP inputs */}
          <div className="input-otp-container">
            <input
              type="tel"
              maxLength={1}
              id="otp1"
              name="otp1"
              onChange={(e) => handleChange(e, "otp1", null)}
              value={formik.values.otp1}
              onBlur={formik.handleBlur}
            />
            <input
              type="tel"
              maxLength={1}
              id="otp2"
              name="otp2"
              onChange={(e) => handleChange(e, "otp2", "otp1")}
              value={formik.values.otp2}
              onBlur={formik.handleBlur}
            />
            <input
              type="tel"
              maxLength={1}
              id="otp3"
              name="otp3"
              onChange={(e) => handleChange(e, "otp3", "otp2")}
              value={formik.values.otp3}
              onBlur={formik.handleBlur}
            />
            <input
              type="tel"
              maxLength={1}
              id="otp4"
              name="otp4"
              onChange={(e) => handleChange(e, "otp4", "otp3")}
              value={formik.values.otp4}
              onBlur={formik.handleBlur}
            />
            <input
              type="tel"
              maxLength={1}
              id="otp5"
              name="otp5"
              onChange={(e) => handleChange(e, "otp5", "otp4")}
              value={formik.values.otp5}
              onBlur={formik.handleBlur}
            />
            <input
              type="tel"
              maxLength={1}
              id="otp6"
              name="otp6"
              onChange={(e) => handleChange(e, "otp6", "otp5")}
              value={formik.values.otp6}
              onBlur={formik.handleBlur}
            />
          </div>

          {isLoading ? (
            <button className="btn-form">
              <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                width="30"
                visible={true}
              />
            </button>
          ) : (
            <button type="submit" className="btn-form">
              ارسال
            </button>
          )}
          <p
            className="btn-resend"
            onClick={() => {
              resendCode();
            }}
          >
            إعادة ارسال
          </p>
        </form>

        <ToastContainer />
      </div>
    </div>
  );
}
