import React, { useEffect, useState } from "react";

import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";

import greenlocat from "../../assets/images/greenLocat.png";
import axios from "axios";

const Order = () => {
  const navigate = useNavigate();
  const [orderProductDetails, setOrderProductDetails] = useState([]);

  const token = localStorage.getItem("token");

  // get order product details
  const getOrderDetails = () => {
    axios
      .get(
        "https://development.feastap.com/api/user/cart",

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data) {
          setOrderProductDetails(res.data.data.products);
        } else {
          setOrderProductDetails([]);
        }
      });
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  const tableId = localStorage.getItem("table");
  //make order
  const makeMyOrder = () => {
    axios
      .post(
        `https://development.feastap.com/api/user/order/make-order?table_id=${tableId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });

          setTimeout(() => {
            navigate("/makeOrder");
          }, 2000);

          getOrderDetails();
        }
      });
  };

  //delete cart product details
  const emptyProductsCart = () => {
    axios
      .post(
        "https://development.feastap.com/api/user/cart/emptyCart",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success(" Order is canceled successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });

          getOrderDetails();
        }
      });
  };

  return (
    <>
      <nav
        className="navbar"
        style={{
          backgroundColor: "white",
          textAlign: "center",
          margin: "0",
          display: "block",
        }}
      >
        <div className="">
          <p
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "black",
              marginBottom: "0",
            }}
          >
            سلة المشتريات
          </p>
        </div>
      </nav>
      {orderProductDetails?.length > 0 ? (
        <>
          <div style={{ padding: "12px", margin: "15px" }}>
            <div
              style={{
                margin: "0",
                fontWeight: "500",
                fontFamily: "TajawalBold",
                fontSize: "15px",
                color: "#37BD6B",
                marginBottom: "15px",
              }}
            >
              <img src={greenlocat} height={"18px"} width={"18px"} />
              <Link
                style={{
                  color: "#37BD6B",
                  fontSize: "14px",
                  fontWeight: "400",
                  textDecoration: "none",
                }}
                to="/menu"
              >
                اذهب إلى المنيو
              </Link>
            </div>
          </div>

          <div className="product-container">
            <div className="d-flex flex-column  justify-content-around">
              <p
                style={{
                  fontWeight: 400,
                  lineHeight: "16.8px",
                  fontSize: "16px",
                }}
              >
                تفاصيل المنيو
              </p>
            </div>

            {orderProductDetails?.map((product) => {
              return (
                <div key={product.productCartId}>
                  <img src={product.product.image} />
                </div>
              );
            })}
          </div>
          <div>
            <div>
              <p>الاجمالى</p>
              <p></p>
            </div>
            <Button
              style={{
                backgroundColor: "#37BD6B",
                textAlign: "center",
                margin: "0",
                border: "none",
                padding: "10px 7px",
              }}
              onClick={makeMyOrder}
            >
              أضف الطلب
            </Button>

            <Button
              style={{
                backgroundColor: "red",
                textAlign: "center",
                margin: "0",
                border: "none",
                padding: "10px 7px",
              }}
              onClick={emptyProductsCart}
            >
              الغاء الطلب
            </Button>
          </div>
        </>
      ) : (
        <div>no data</div>
      )}

      <ToastContainer />
    </>
  );
};

export default Order;
