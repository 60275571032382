import React, { useEffect, useState } from "react";
import "./meal.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance, { axiosTokenInstance } from "../../axiosConfig/axios";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { Minus, Plus, ShoppingCart } from "lucide-react";
import axios from "axios";
import ProductDetails from "./ProductDetails"; // Import the new component

const Meal = () => {
  const navigate = useNavigate();

  const [Product, setProduct] = useState([]);
  const [Sizes, setSizes] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [productPrice, setProductPrice] = useState(null);
  const [notes, setNotes] = useState("");

  let [count, setCount] = useState(1);

  const params = useParams();

  const restaurantID = localStorage.getItem("restaurant");

  const getProductDetails = async () => {
    const { data } = await axiosInstance.get(
      `user/menu/${restaurantID}/singleItem/${params.id}`
    );

    console.log(data);

    setProduct(data.data);
    setSizes(data.data.sizes);
    setProductOptions(data.data.product_options);

    if (data.data.sizes.length > 0) {
      setSelectedSize(data.data.sizes[0].id);
      setProductPrice(data.data.sizes[0].price_with_tax);
    }
  };

  useEffect(() => {
    getProductDetails();
  }, []);

  const handleCheckboxChange = (e, optionId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setOptions((prevOptions) => [...prevOptions, optionId]);
    } else {
      setOptions((prevOptions) =>
        prevOptions.filter((option) => option !== optionId)
      );
    }
  };

  function incrementCount() {
    setCount(count + 1);
  }

  function decrementCount() {
    if (count > 1) {
      setCount(count - 1);
    }
  }

  const requestProductBody = {
    productId: Product.id,
    qty: count,
    size: selectedSize,
    options: options,
    notes: notes,
    restaurant_id: restaurantID,
  };

  const token = localStorage.getItem("token");

  const addToCart = () => {
    axiosTokenInstance.post(`user/cart/`, requestProductBody).then((res) => {
      console.log(res);

      if (res.data.status === 200) {
        toast.success(" Added successfully .", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });

        setTimeout(() => {
          navigate("/menu");
        }, 2000);
      } else if (res.data.status === 422) {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    });
  };

  return (
    <>
      <ProductDetails
        Product={Product}
        Sizes={Sizes}
        selectedSize={selectedSize}
        setSelectedSize={setSelectedSize}
        productPrice={productPrice}
        setProductPrice={setProductPrice}
        productOptions={productOptions}
        handleCheckboxChange={handleCheckboxChange}
        notes={notes}
        setNotes={setNotes}
      />

      <div
        style={{
          padding: "0 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          style={{
            backgroundColor: "#37BD6B",
            textAlign: "center",
            margin: "0",
            border: "none",
            padding: "10px 7px",
          }}
          onClick={(event) => {
            event.preventDefault();
            localStorage.setItem("hasProduct", true);
            localStorage.setItem("product", requestProductBody);
            if (token) {
              addToCart();
            } else {
              navigate("/login");
            }
          }}
        >
          أضف الى السلة ر.س
        </Button>

        <div
          className="count"
          style={{
            backgroundColor: "rgb(243 243 243)",
            textAlign: "center",
            border: "none",
            display: "flex",
            alignItems: "center",
            float: "left",
            borderRadius: "8px",
          }}
        >
          <button
            className="plusBtn"
            style={{
              width: "20px",
              backgroundColor: "transparent",
              border: "none",
              color: "#37BD6B",
              fontWeight: "900",
            }}
            onClick={incrementCount}
          >
            <Plus size="16" />
          </button>
          <p
            className="productCount"
            style={{
              fontFamily: "tajawal",
              fontSize: "15px",
              fontWeight: "900",
              padding: "0 5px",
            }}
          >
            {count}
          </p>
          <button
            className="minusBtn"
            style={{
              width: "20px",
              backgroundColor: "transparent",
              border: "none",
              color: "#F00",
              fontWeight: "900",
            }}
            onClick={decrementCount}
          >
            <Minus size="16" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Meal;
